html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Open Sans', sans-serif;
  /* color: '#0c0c0c'; */
  /* height: 100%; */
  /* background-color: #f2f2f2; */
  /* background-color: yellow; */
  background-color: #f9f9f9;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  /* fontSize: '70px',
  fontWeight: '700', */
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-size: 70px;
  font-weight: 700;
}
h2 {
  font-size: 50px;
  font-weight: 700;
}
h3 {
  font-size: 30px;
  font-weight: 700;
}
h4 {
  font-size: 30px;
  font-weight: 300;
}
h5 {
  font-size: 25px;
  font-weight: 300;
}
h6 {
  font-size: 20px;
  font-weight: 300;
}
button,
input {
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Roboto', sans-serif;
}
/* .MuiButtonBase-root,
.MuiButton-root {
  text-transform: lowercase;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
} */
.logo_big {
  /* font-family: 'Shippori Antique', sans-serif; */
  font-size: 40px;
  font-weight: 700;
}
.highlight_text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: url('../assets/royal-heath-main.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* font-family: 'Steelfish Rg', 'helvetica neue', helvetica, arial, sans-serif; */
}

.highlight_text_beta {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: url('../assets/royal-heath-small-light.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* font-family: 'Steelfish Rg', 'helvetica neue', helvetica, arial, sans-serif; */
}
.highlight_text_dark {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: url('../assets/royal-heath-small.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* font-family: 'Steelfish Rg', 'helvetica neue', helvetica, arial, sans-serif; */
}

.heatmap2 {
  /* width: 100px;
  height: 150px; */
  /* width: 2667px;
  height: 1557px; */
  width: 889px;
  height: 519px;
  border: solid black 2px;
}
/* class="MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline" */
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.52);
  /* border-color: red; */
}

.colors {
  /* background-color: #99acff;
  background-image: radial-gradient(
      at 65% 99%,
      hsla(225, 81%, 78%, 1) 0,
      transparent 48%
    ),
    radial-gradient(at 87% 76%, hsla(253, 84%, 66%, 1) 0, transparent 48%),
    radial-gradient(at 26% 11%, hsla(342, 69%, 70%, 1) 0, transparent 50%),
    radial-gradient(at 84% 22%, hsla(38, 79%, 71%, 1) 0, transparent 47%),
    radial-gradient(at 92% 12%, hsla(15, 79%, 65%, 1) 0, transparent 59%),
    radial-gradient(at 65% 50%, hsla(249, 85%, 65%, 1) 0, transparent 49%),
    radial-gradient(at 39% 56%, hsla(263, 61%, 77%, 1) 0, transparent 46%); */
  /* background-color: #0852fd; */
  /* background-color: #1b1364;
  background-image: radial-gradient(
      at 65% 99%,
      hsla(225, 81%, 78%, 1) 0,
      transparent 48%
    ),
    radial-gradient(at 87% 76%, hsla(253, 84%, 66%, 1) 0, transparent 48%),
    radial-gradient(at 26% 11%, hsla(342, 69%, 70%, 1) 0, transparent 50%),
    radial-gradient(at 84% 22%, hsla(38, 79%, 71%, 1) 0, transparent 47%),
    radial-gradient(at 92% 12%, hsla(15, 79%, 65%, 1) 0, transparent 59%),
    radial-gradient(at 65% 50%, hsla(249, 85%, 65%, 1) 0, transparent 49%),
    radial-gradient(at 39% 56%, hsla(263, 61%, 77%, 1) 0, transparent 46%); */
  /* background-color: #1b1364;
  background-image: radial-gradient(
      at 0% 73%,
      hsla(225, 81%, 78%, 1) 0,
      transparent 48%
    ),
    radial-gradient(at 87% 76%, hsla(253, 84%, 66%, 1) 0, transparent 48%),
    radial-gradient(at 26% 11%, hsla(342, 69%, 70%, 1) 0, transparent 50%),
    radial-gradient(at 84% 22%, hsla(38, 79%, 71%, 1) 0, transparent 47%),
    radial-gradient(at 92% 12%, hsla(15, 79%, 65%, 1) 0, transparent 59%),
    radial-gradient(at 65% 50%, hsla(249, 85%, 65%, 1) 0, transparent 49%),
    radial-gradient(at 31% 100%, hsla(263, 61%, 77%, 1) 0, transparent 28%); */
  background: radial-gradient(circle at 93.21% 89.16%, #0852fd, transparent 28%),
    radial-gradient(circle at 0.83% 81.57%, #1b1364, transparent 55%),
    radial-gradient(circle at 79.8% 29.16%, #02b9f0, transparent 58%),
    radial-gradient(circle at 14.25% 22.17%, #fda7df, transparent 67%),
    radial-gradient(circle at 61.79% 69.76%, #ed4c67, transparent 61%),
    radial-gradient(circle at 50% 50%, #ffffff, #ffffff 100%);
}

.spacer_small {
  height: 10px;
  width: 100%;
}
.space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mesh {
  background-image: url('../assets/royal-heath.jpg');
  background-size: cover;
}
.stats_box {
  width: 50%;
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  border: solid #0036f9 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.questions_like li {
  padding: 2px;
}
.modebar {
  display: none !important;
}

.break_flex {
  display: flex;
  gap: 100px;
  width: 100%;
}
@media all and (max-width: 900px) {
  .break_flex {
    display: block;
  }
}
